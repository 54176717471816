"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var PopperJS = _interopRequireWildcard(require("popper.js"));

var React = _interopRequireWildcard(require("react"));

var _reactDom = require("react-dom");

var _reactPopper = require("react-popper");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

let portal;

function getPortal() {
  const {
    body
  } = document;

  if (!body) {
    return null;
  }

  if (!portal) {
    portal = document.createElement("div");
    body.appendChild(portal);
  }

  return portal;
}

// Wrapper component to add tooltip listeners to your elements
class Tooltip extends React.Component {
  // fake element used for positioning the tooltip next to the mouse
  // show the tooltip at absolute position with given contents
  static show(x, y, contents, props = Object.freeze({})) {
    const container = getPortal(); // satisfy flow

    if (!container) {
      console.warn("Could not get tooltip portal");
      return null;
    }

    return (0, _reactDom.render)(React.createElement(Tooltip, _extends({
      defaultShown: true,
      defaultMousePosition: {
        x,
        y
      },
      contents: contents
    }, props)), container);
  } // hide the tooltip


  static hide() {
    (0, _reactDom.unmountComponentAtNode)(getPortal());
  }

  constructor(props) {
    super(props);

    _defineProperty(this, "timeout", void 0);

    _defineProperty(this, "scheduleUpdate", void 0);

    _defineProperty(this, "fakeReferenceElement", {
      getBoundingClientRect: () => {
        const {
          mousePosition = this.props.defaultMousePosition
        } = this.state;

        if (!mousePosition) {
          return {
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            width: 0,
            height: 0
          };
        }

        const {
          x,
          y
        } = mousePosition;
        return {
          left: x,
          top: y,
          right: x,
          bottom: y,
          width: 0,
          height: 0
        };
      },
      clientWidth: 0,
      clientHeight: 0
    });

    _defineProperty(this, "onMouseEnter", (e, force = false) => {
      const {
        fixed,
        delay
      } = this.props;

      if (!fixed) {
        return;
      }

      if (force || !delay) {
        this.setState({
          shown: true
        });
        return;
      }

      const delayTime = typeof delay === "number" ? delay : 500;
      this.timeout = setTimeout(this.onMouseEnter, delayTime, e, true);
    });

    _defineProperty(this, "onMouseMove", e => {
      this.setState({
        shown: true,
        mousePosition: {
          x: e.clientX,
          y: e.clientY
        }
      });

      if (this.scheduleUpdate) {
        this.scheduleUpdate();
      }
    });

    _defineProperty(this, "onMouseLeave", e => {
      clearTimeout(this.timeout);
      this.setState({
        shown: false,
        mousePosition: null
      });
    });

    this.state = {
      shown: props.defaultShown,
      mousePosition: undefined
    };
  }

  componentDidUpdate() {
    // In the case where defaultShown is set and our defaultMousePosition changed,
    // we need to update the popper's position
    if (this.scheduleUpdate) {
      this.scheduleUpdate();
    }
  }

  renderPopper() {
    const {
      placement,
      contents,
      offset,
      fixed,
      arrow
    } = this.props;
    const {
      shown
    } = this.state;

    if (!shown) {
      return null;
    } // if we are positioning based on the mouse, hook up the fake reference element


    const referenceProps = {};

    if (!fixed) {
      referenceProps.referenceElement = this.fakeReferenceElement;
    }

    return React.createElement(_reactPopper.Popper, _extends({
      placement: placement,
      modifiers: {
        offset: {
          offset: `${offset.x},${offset.y}`
        },
        preventOverflow: {
          boundariesElement: "viewport"
        }
      }
    }, referenceProps), ({
      ref,
      style,
      scheduleUpdate,
      placement,
      arrowProps
    }) => {
      const {
        body
      } = document;

      if (!body) {
        return null;
      } // hold onto the scheduleUpdate function so we can call it when the mouse moves


      this.scheduleUpdate = scheduleUpdate;
      return (0, _reactDom.createPortal)(React.createElement("div", {
        ref: ref,
        style: _objectSpread({}, style, {
          zIndex: "99999",
          pointerEvents: "none"
        }),
        "data-placement": placement
      }, arrow && React.cloneElement(arrow, {
        ref: arrowProps.ref,
        style: _objectSpread({}, arrow.props.style || {}, arrowProps.style)
      }), typeof contents === "function" ? contents() : contents), body);
    });
  }

  render() {
    const {
      children,
      fixed
    } = this.props;

    if (!children) {
      return this.renderPopper();
    }

    const child = React.Children.only(children);
    const eventListeners = {
      onMouseLeave: this.onMouseLeave
    };

    if (fixed) {
      eventListeners.onMouseEnter = this.onMouseEnter;
    } else {
      eventListeners.onMouseMove = this.onMouseMove;
    }

    if (fixed) {
      return React.createElement(_reactPopper.Manager, null, React.createElement(_reactPopper.Reference, null, ({
        ref
      }) => {
        return React.cloneElement(child, _objectSpread({}, eventListeners, {
          ref
        }));
      }), this.renderPopper());
    }

    return React.createElement(React.Fragment, null, React.cloneElement(child, eventListeners), this.renderPopper());
  }

}

exports.default = Tooltip;

_defineProperty(Tooltip, "defaultProps", {
  fixed: false,
  offset: {
    x: 0,
    y: 14
  },
  defaultShown: false,
  defaultMousePosition: undefined,
  placement: "bottom"
});