"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PROGRESS_DIRECTION = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

var React = _interopRequireWildcard(require("react"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const PROGRESS_DIRECTION = Object.freeze({
  HORIZONTAL: "horizontal",
  PULSE: "pulse",
  VERTICAL: "vertical"
});
exports.PROGRESS_DIRECTION = PROGRESS_DIRECTION;
let keyframesInjected = false;

function injectKeyframes() {
  if (keyframesInjected) {
    return;
  }

  const style = document.createElement("style");
  style.innerHTML = `
    @keyframes cruise-automation-button-pulse {
      0%,
      100%  {
        opacity: 0.9;
      }
      50% {
        opacity: 0.2;
      }
    }
  `;

  if (!document.body) {
    throw new Error("no document.body");
  }

  document.body.appendChild(style);
  keyframesInjected = true;
}

function getProgressStyle(props) {
  const {
    direction,
    percentage,
    customStyle
  } = props;

  if (direction === PROGRESS_DIRECTION.PULSE) {
    return _objectSpread({}, customStyle, {
      width: "100%",
      height: "100%"
    });
  }

  return _objectSpread({}, customStyle, {
    width: direction === PROGRESS_DIRECTION.HORIZONTAL ? `${percentage}%` : "100%",
    height: direction !== PROGRESS_DIRECTION.HORIZONTAL ? `${percentage}%` : "100%"
  });
}

class Button extends React.Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "animationId", void 0);

    _defineProperty(this, "cancelTimeoutId", void 0);

    _defineProperty(this, "state", {
      mouseDown: false,
      progressPercentage: 0
    });

    _defineProperty(this, "onDelayFinished", e => {
      const {
        onClick
      } = this.props; // slightly delay reseting to a non-progress state
      // this allows the consumer to apply some kind of 'toggled on' class to the button
      // and also makes the interaction slightly less jarring

      this.cancelTimeoutId = setTimeout(this.cancelDown, 100);

      if (!onClick) {
        return;
      }

      onClick(e);
    });

    _defineProperty(this, "onMouseUp", e => {
      const {
        onMouseUp
      } = this.props;
      this.cancelDown();

      if (onMouseUp) {
        onMouseUp(e);
      }
    });

    _defineProperty(this, "onMouseLeave", e => {
      const {
        onMouseLeave
      } = this.props;
      this.cancelDown();

      if (onMouseLeave) {
        onMouseLeave(e);
      }
    });

    _defineProperty(this, "onMouseDown", e => {
      const {
        delay
      } = this.props;

      if (!delay) {
        return;
      } // we need to remove the event from the pool to use it after a delay


      e.persist(); // call animate after state change because this.setState is async

      const onComplete = () => this.onDelayFinished(e);

      const start = Date.now();
      this.setState({
        mouseDown: true
      }, () => this.animate(start, onComplete));
    });

    _defineProperty(this, "onClick", e => {
      const {
        onClick,
        delay
      } = this.props;
      e.stopPropagation();
      e.preventDefault();

      if (onClick && !delay) {
        onClick(e);
      }
    });

    _defineProperty(this, "cancelDown", () => {
      this.setState({
        mouseDown: false,
        progressPercentage: 0
      });
    });
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationId);
    clearTimeout(this.cancelTimeoutId);
  }

  animate(startStamp, doneCallback) {
    const {
      mouseDown
    } = this.state;
    const {
      delay
    } = this.props;

    if (!mouseDown || !delay) {
      return;
    } // if just finshed a previous click there might be a pending cancel operation
    // so clear it out


    clearTimeout(this.cancelTimeoutId);
    this.animationId = requestAnimationFrame(() => {
      const tickStamp = Date.now();
      const progressPercentage = Math.min((tickStamp - startStamp) / delay * 100, 100);
      this.setState({
        progressPercentage
      });

      if (progressPercentage < 100) {
        this.animate(startStamp, doneCallback);
        return;
      }

      doneCallback();
    });
  }

  renderProgressBar() {
    const {
      delay,
      progressClassName,
      progressStyle,
      progressDirection
    } = this.props;
    const {
      mouseDown,
      progressPercentage
    } = this.state; // don't render a bar if we aren't a delay button

    if (!delay || !mouseDown) {
      return null;
    }

    if (progressDirection === PROGRESS_DIRECTION.PULSE) {
      injectKeyframes();
    } // allow user supplied classname to supercede built in class


    return React.createElement("span", {
      style: _objectSpread({
        content: "",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        animation: progressDirection === PROGRESS_DIRECTION.PULSE && "cruise-automation-button-pulse 1s linear infinite"
      }, getProgressStyle({
        direction: progressDirection,
        percentage: progressPercentage,
        customStyle: progressStyle
      })),
      className: progressClassName
    });
  }

  render() {
    const {
      children,
      id,
      small,
      large,
      primary,
      danger,
      warning,
      disabled,
      className,
      style,
      tooltip,
      onFocus
    } = this.props;
    const classes = (0, _classnames.default)("button", className || "", {
      // support some bulma classes to be supplied in consumer either through bulma or custom classes
      // these provide backwards compatibility with webviz
      "is-small": small,
      "is-large": large,
      "is-primary": primary,
      "is-warning": warning,
      "is-danger": danger
    });
    return React.createElement("button", {
      type: "button",
      className: classes,
      id: id,
      onClick: this.onClick,
      onFocus: onFocus,
      onMouseDown: this.onMouseDown,
      onMouseLeave: this.onMouseLeave,
      onMouseUp: this.onMouseUp,
      style: _objectSpread({
        position: "relative",
        zIndex: 0,
        overflow: "hidden"
      }, style),
      title: tooltip,
      disabled: disabled
    }, children, this.renderProgressBar());
  }

}

exports.default = Button;

_defineProperty(Button, "defaultProps", {
  progressDirection: PROGRESS_DIRECTION.VERTICAL
});